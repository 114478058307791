<template>
  <div
    :style="{ height: screeHeight + 'px' }"
    style="background-color: rgb(239 239 239)"
  >
    <div style="padding-top: 20px; padding-bottom: 44px">
      <van-field v-model="info.name" label="姓名" placeholder="请输入姓名" />
      <van-field
        v-model="info.mobile"
        label="手机号"
        placeholder="请输入手机号"
      />
      <van-field
        v-model="info.idcardNumber"
        label="身份证号"
        placeholder="请输入身份证号"
      />
    </div>
    <van-button round type="danger" @click="save" style="" class="save"
      >确定</van-button
    >
  </div>
</template>
<script>
  import { chuyouren } from "../../data.js";
  import { Toast } from "vant";
  export default {
    name: "info",
    data() {
      return {
        screenWidth: document.documentElement.clientWidth, // 屏幕宽
        screeHeight: document.documentElement.clientHeight, // 屏幕高
        lists: chuyouren,
        active: 0,
        info: {
          name: "",
          mobile: "",
          idcardNumber: "",
        },
        id: "",
      };
    },
    created() {},
    mounted() {
      this.id = this.$route.query.id;
      let arr = this.$route.query.arr || [];
      let user = this.$store.state.user;
      if (this.id) {
        this.$http({
          method: "post",
          url: `${this.apis.playerModify}`,
          params: { id: this.id },
        }).then((res) => {
          if (res.code == 200) {
            this.info = res.data;
            this.info.name = res.data.playerName;
          }
        });
      } else {
        if (arr.length > 0) {
          if (arr.indexOf(user.idcardNumber) === -1) {
            this.info.name = user.name;
            this.info.mobile = user.mobile;
            this.info.idcardNumber = user.idcardNumber;
          }
        } else if (arr.length <= 0) {
          console.log(2);
          this.info.name = user.name;
          this.info.mobile = user.mobile;
          this.info.idcardNumber = user.idcardNumber;
        }
      }
    },
    methods: {
      save() {
        if (!this.info.name) {
          Toast({
            message: "请输入相关信息！",
            icon: "fail",
          });
          return;
        }
        if (this.id) {
          //更新
          this.$http({
            method: "post",
            url: `${this.apis.savePlayerModify}`,
            params: this.info,
          }).then((res) => {
            if (res.code == 200) {
              Toast({
                message: "更新成功",
                icon: "success",
              });
              this.$router.go(-1);
              return;
            }
            Toast({
              message: res.message,
              icon: "fail",
            });
          });
        } else {
          //add
          this.$http({
            method: "post",
            url: `${this.apis.addPlayer}`,
            params: this.info,
          }).then((res) => {
            if (res.code == 200) {
              Toast({
                message: "添加成功",
                icon: "success",
              });
              this.$router.go(-1);
            }
          });
        }
      },
    },
  };
</script>
<style scoped>
  .active {
    width: 70px;
    background-color: #fd1a34;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 3px;
  }
  .nactive {
    width: 70px;
    padding: 3px;
    text-align: center;
    border-radius: 15px;
  }
  .save {
    width: 97%;
    margin-left: 1.5%;
    position: fixed;
    bottom: 6px;
  }
</style>
